import { cva } from 'class-variance-authority';
import isString from 'lodash.isstring';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Store, WithContext } from 'schema-dts';

import { CHANNEL, storePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { content as contentService, store as storeService } from '@boss/services';
import type { Crumble, StoreFinderFilterOptions } from '@boss/types/b2b-b2c';
import { Environment } from '@boss/types/b2b-b2c';
import { openinghoursToStructuredString } from '@boss/utils';

import { ComponentMapper, SEO, StoreFinder, StoreResults } from '../../components';
import { IS_BUILD, PageTypes } from '../../constants';
import { useOrderStoresByProx } from '../../hooks';
import Layout from '../../layout/default';
import { handleStores, storeConfig } from '../../utils';
import getServerSideTranslations from '../../utils/getServerSideTranslations';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';
import { getMappedNavigation } from '../../utils/navigation';
import { storeFinderVariant } from '../../utils/options';

const backgroundColor = cva('relative break-before break-after', {
  variants: {
    variant: {
      primary: 'bg-beige before:bg-beige after:bg-beige',
      secondary: 'bg-gray-lightest before:bg-gray-lightest  after:bg-gray-lightest',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export const getStaticProps = async ({ preview, locale }: GetStaticPropsContext) => {
  if (!locale || locale === 'default') {
    return {
      notFound: true,
    };
  }

  const contentfulLocale = mapNextToContentfulLocale(locale);

  const [stores, navigation, overviewPage] = await Promise.all([
    storeService.getStoresWithCustomMessages({
      locale: contentfulLocale,
      preview: !!preview,
      channel: CHANNEL,
    }),
    getMappedNavigation(contentfulLocale, !!preview),
    contentService.getOverviewPage({
      locale: contentfulLocale,
      channel: CHANNEL,
      type: 'Store overview',
      batchMode: IS_BUILD,
      preview: !!preview,
    }),
  ]);

  if (!stores) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      pageType: PageTypes.STORE_OVERVIEW,
      stores: handleStores(stores ?? [], storeConfig),
      preview: !!preview,
      pageData: { overviewPage },
      navigation,
      environment: process.env.CONTENTFUL_ENVIRONMENT as Environment,
      ...(await getServerSideTranslations(locale, ['common', 'stores', 'routes', 'forms'])),
    },
  };
};

export const Index = ({
  navigation,
  preview,
  pageData,
  environment,
  stores,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const localizedSlugs = storePageSlugs;
  const { t } = useTranslation('stores');
  const pageTitle = t('storesLabel', { ns: 'common' }) ?? '';
  const pageDescription = pageTitle; // TODO: Update with final value once this is known

  const structuredData: WithContext<Store>[] =
    stores?.map(store => ({
      '@context': 'https://schema.org',
      '@type': 'Store',
      name: store.name,
      telephone: store.phone,
      latitude: store.latitude,
      longitude: store.longitude,
      address: Object.values(store.address).join(' '),
      openingHours: openinghoursToStructuredString(store.openinghours),
      email: store.email,
      branchCode: store.id,
      keywords: `${store.storetype} ${store.availableservices.join(' ')}`,
    })) ?? [];

  const {
    location,
    sortStoresByLocationId,
    filteredStores,
    isCurrentLocationSearch,
    setSortByOpenStatus,
    resetStoreOrder,
    sortStoresByCoord,
  } = useOrderStoresByProx(stores);

  const [activeFilters, setActiveFilters] = useState<StoreFinderFilterOptions>({
    isOpen: false,
  });
  const { query, locale } = useRouter();
  const { overviewPage } = pageData;
  const hasPrefixSection = overviewPage?.prefixSections?.length;

  useEffect(() => {
    const { q, d } = query;

    if (isString(q) && isString(d)) {
      sortStoresByLocationId({ description: d, placeid: q });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const updateFilters = async (filters: StoreFinderFilterOptions) => {
    setActiveFilters(filters);
    setSortByOpenStatus(!!filters.isOpen);
  };

  const crumbles: Crumble[] = [{ href: storePageSlugs[locale], label: pageTitle }];

  return (
    <SEO
      breadcrumbs={crumbles}
      description={overviewPage?.metaDescription ?? pageDescription}
      imageSrc={overviewPage?.ogImage?.image?.file?.url}
      localizedSlugs={localizedSlugs}
      navigation={navigation}
      noFollow={overviewPage?.noFollow}
      noIndex={overviewPage?.noIndex}
      structuredData={structuredData}
      title={overviewPage?.pageTitle ?? pageTitle}
    >
      <Layout
        breadcrumbClassName={hasPrefixSection ? '' : 'mb-4 md:mb-6'}
        breadcrumbWrapperClassName={hasPrefixSection ? '' : backgroundColor({ variant: storeFinderVariant })}
        crumbles={crumbles}
        environment={environment}
        localizedSlugs={localizedSlugs}
        navigation={navigation}
        preview={preview}
      >
        {overviewPage?.prefixSections?.map(entry => (
          <ComponentMapper entry={entry} key={entry.id} locale={locale} />
        ))}
        <StoreFinder
          activeFilters={activeFilters}
          className={hasPrefixSection ? 'pt-7' : ''}
          extended
          noFilters
          onSearch={sortStoresByLocationId}
          onUpdateFilters={updateFilters}
          sortByCurrentLocation={sortStoresByCoord}
          translations={t('storeFinder', { returnObjects: true })}
          variant={storeFinderVariant}
        />
        {location && (
          <div className="mt-10">
            <h3 className="text-blue mt-10 inline pr-3">
              {t('results.title', {
                location: `"${isCurrentLocationSearch ? t('results.currentLocation') : location}"`,
              })}
            </h3>
            <button className="text-blue-dark inline text-sm underline" onClick={resetStoreOrder}>
              {t('results.clearSearch')}
            </button>
          </div>
        )}
        <div className="mb-10 mt-10">
          <StoreResults shopBasePath={localizedSlugs[locale]} stores={filteredStores} />
        </div>
        {overviewPage?.suffixSections?.map(entry => (
          <ComponentMapper entry={entry} key={entry.id} locale={locale} />
        ))}
      </Layout>
    </SEO>
  );
};

export default Index;
